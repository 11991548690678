var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? [
            _c(
              "el-link",
              {
                attrs: { type: "primary", underline: false },
                on: {
                  click: function ($event) {
                    _vm.videoShow = true
                  },
                },
              },
              [_vm._v("视频播放")]
            ),
          ]
        : _vm._e(),
      _vm.videoShow
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog-no-pd",
              attrs: {
                "close-on-click-modal": false,
                title: "视频播放",
                visible: _vm.videoShow,
                width: "30%",
                "destroy-on-close": true,
                "append-to-body": "",
                "before-close": _vm.dialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.videoShow = $event
                },
              },
            },
            [
              _c("div", [
                _c("video", {
                  ref: "vueRef",
                  staticClass: "curd-video",
                  attrs: { src: _vm.value, controls: true, autoplay: true },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }