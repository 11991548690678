// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#191a23",
	"menuHover": "#263445",
	"menuLightBg": "#ffffff",
	"menuLightHover": "#f0f1f5",
	"subMenuBg": "#191a23",
	"subMenuHover": "#001528",
	"sideBarWidth": "256px",
	"sidebarTitle": "#ffffff",
	"sidebarLightTitle": "#001529"
};
module.exports = exports;
