var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-ueditor-wrap", {
        style: _vm.height,
        attrs: {
          "editor-id": "editor",
          config: _vm.editorConfig,
          editorDependencies: ["ueditor.config.js", "ueditor.all.js"],
        },
        model: {
          value: _vm.contentText,
          callback: function ($$v) {
            _vm.contentText = $$v
          },
          expression: "contentText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }