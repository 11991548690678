<template>
    <div>
        <!--表格文字颜色  -->
        <div :style="{color:value.color}">{{ value.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'colorText',
    props: {
        value: {
            required: true,
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
.quick-admin {
}
</style>
