var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.value, function (item, index) {
      return _vm.value.length > 1
        ? _c("span", { key: index }, [_vm._v(_vm._s(item + ";"))])
        : _c("span", [_vm._v(_vm._s(_vm.value[0]))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }