<template>
    <div class="quick-admin">
        <!--  -->
        <div v-for="(item,index) in value" :key="index">{{index }}: {{ item }}  </div>
    </div>
</template>

<script>
export default {
    name: 'QuickAdminTextColor',
    props: {
        value: {
            required: true,
            type: Object,
        },
    },
}
</script>

<style lang="scss">
.quick-admin {
    .el-tag--dark {
        border: none;
    }
}
</style>
