// +----------------------------------------------------------------------
// | quickadmin框架 [ quickadmin框架 ]
// +----------------------------------------------------------------------
// | 版权所有 2020~2022 南京新思汇网络科技有限公司
// +----------------------------------------------------------------------
// | 官方网站: https://www.quickadmin.icu
// +----------------------------------------------------------------------
// | Author: mj <931982149@qq.com>
// +----------------------------------------------------------------------
// | Description: 全局组件
// +----------------------------------------------------------------------

// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 富文本组件
import Editor from '@/components/Editor'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 图片上传组件
import ImageUpload from '@/components/ImageUpload'
// 分页组件
import Pagination from '@/components/Pagination'

import UEditor from '@/components/UEditor'

//curd表单组件
import QuickAdminText from '@/components/QuickAdminTable/text'
import QuickAdminTag from '@/components/QuickAdminTable/tag'
import QuickAdminImage from '@/components/QuickAdminTable/image'
import QuickAdminSwitch from '@/components/QuickAdminTable/switch'
import QuickAdminPopover from '@/components/QuickAdminTable/popover'
import QuickAdminTextColor from '@/components/QuickAdminTable/textcolor'
import QuickAdminVideo from '@/components/QuickAdminTable/video'
import QuickAdminTextCopy from '@/components/QuickAdminTable/textCopy'
import QuickAdminAudio from '@/components/QuickAdminTable/audio'
import QuickAdminTextArray from '@/components/QuickAdminTable/textArray'
//table字体颜色
import colorText from '../components/colorText/index.vue'
//疾病库数据 格式化所属分类
import classFormat from '@/components/classFormat/index.vue'
import numberColor from '@/components/colorText/numberColor.vue'

//自定义选择框分页组件
import SelectPage from '@/components/QuickAdminCom/selectPage'

export {
    RightToolbar,
    Editor,
    FileUpload,
    ImageUpload,
    Pagination,
    QuickAdminText,
    QuickAdminTag,
    QuickAdminImage,
    QuickAdminSwitch,
    QuickAdminPopover,
    QuickAdminTextColor,
    QuickAdminVideo,
    QuickAdminTextCopy,
    QuickAdminTextArray,
    QuickAdminAudio,
    SelectPage,
    UEditor,
    colorText,
    classFormat,
    numberColor,
}
