var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-admin" },
    _vm._l(_vm.value, function (item, index) {
      return _c("div", { key: index }, [
        _vm._v(_vm._s(index) + ": " + _vm._s(item) + " "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }