var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? _c("el-popover", { attrs: { trigger: "hover", placement: "top" } }, [
            _c("p", [_vm._v("附件地址: " + _vm._s(_vm.value))]),
            _c(
              "div",
              {
                staticClass: "name-wrapper",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [_c("el-tag", [_vm._v("文件")])],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }