var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { color: "#2979ff", cursor: "pointer" },
      on: {
        click: function ($event) {
          return _vm.copy()
        },
      },
    },
    [
      _vm._v(_vm._s(_vm.value) + " "),
      _c("i", { staticClass: "el-icon-document-copy" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }