<template>
    <div>
        <span v-if="value.length>1" v-for="(item,index) in value" :key="index">{{ item + ';' }}</span>
        <span v-else>{{ value[0] }}</span>
    </div>
</template>
<script>
export default {
    name: 'classFormat',
    props: {
        value: {
            required: true,
            type: Array,
        },
    },
}
</script>
<style scoped lang="scss">

</style>
