<template>
    <div>
        <vue-ueditor-wrap
          v-model="contentText"
		  editor-id="editor"
		  :config="editorConfig"
		  :editorDependencies="['ueditor.config.js','ueditor.all.js']"
		  :style="height"/>
    </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
import { getToken } from '@/utils/auth'
// @beforeInit="addCustomDialog"
export default {
    name: 'UEditor',
    components:{
        //VueUeditorWrapA
        //**更正代码231109**
        VueUeditorWrap
    },
    props: {
        /* 编辑器的内容 */
        content: {
            type: String,
            default: '',
        },
        /* 高度 */
        height: {
            type: Number,
            default: null,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: 300,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        /* 类型（base64格式、url格式） */
        type: {
            type: String,
            default: 'url',
        },
    },
    watch: {
        content: function(val) {
          this.contentText = this.content
        // this.$emit('input', val)
        },
        contentText: function(val) {
          this.$emit('input', val)
        }
    },
    data() {
        const url = process.env.VUE_APP_BASE_API + '/admin/editor'
        return {
            uploadUrl: url, // 上传的图片服务器地址
            // uploadUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            editorConfig:{
                // serverUrl: process.env.VUE_APP_BASE_API + "/admin/ajax/upload",
                serverUrl: '/admin/editor',
                UEDITOR_HOME_URL: '/myadmin/UEditor/',
                zIndex: 99999,//设置z轴顺序值，避免工具栏下拉组件被遮挡
                autoHeightEnabled: false, // 编辑器不自动被内容撑高
                initialFrameHeight: 500, // 初始容器高度
                initialFrameWidth: '100%', // 初始容器
                enableAutoSave: false,
                // 'imageUrl': process.env.VUE_APP_BASE_API + "/admin/ajax/upload",
                imageUrl:  "/admin/ajax/upload",
                'imageFieldName': 'upfile',
                 imageUrlPrefix: '',
                'imageActionName': 'uploadimage',
                'imageMaxSize': 2048000,
                'imageAllowFiles': ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
                serverHeaders: {
                    token: getToken()
                }
            },
            contentText:''
        }
    },
    computed: {
        styles() {
            const style = {}
            if (this.minHeight) {
                style.minHeight = `${this.minHeight}px`
            }
            if (this.height) {
                style.height = `${this.height}px`
            }
            return style
        },
    },
    mounted() {
        // this.init()
    },
    beforeDestroy() {
        this.Quill = null
    },
    methods: {
          // 添加自定义弹窗

    },
}
</script>

<style>

</style>
