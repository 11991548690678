var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? [
            _c(
              "el-link",
              {
                attrs: { type: "primary", underline: false },
                on: {
                  click: function ($event) {
                    _vm.videoShow = true
                  },
                },
              },
              [_vm._v("音乐播放")]
            ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-no-pd",
          attrs: {
            "close-on-click-modal": false,
            title: "音乐播放",
            visible: _vm.videoShow,
            width: "30%",
            "destroy-on-close": "",
            "append-to-body": "",
            "before-close": _vm.dialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoShow = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "audio",
              { ref: "audio", staticClass: "aud", attrs: { controls: "" } },
              [_c("source", { attrs: { src: _vm.value } })]
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }