<template>
    <div  @click="copy()" style="color: #2979ff;cursor: pointer;">{{ value }} <i class="el-icon-document-copy" ></i></div>
</template>

<script>
export default {
    name: 'QuickAdminTextCopy',
    props: {
        value: {
            required: true,
            type: String | Number,
        }
    }, 
    methods:{
        copy(){
            let oInput = document.createElement('input');
            oInput.value = this.value;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象; 
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message({
            message: '复制成功',
            type: 'success'
            });
            oInput.remove()   
        }
    }
}
</script>
<style>
.cBlue {
    color: #2979ff;
}
</style>