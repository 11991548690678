<template>
    <div>
        <template v-if="value">
            <el-image class="curd-image" v-for="(item, index) in list" :key="index" :src="item" fit="cover" :preview-src-list="[item]"></el-image>
        </template>
    </div>
</template>

<script>
export default {
    name: 'QuickAdminImage',
    props: {
        value: {
            required: true,
            type: String | null,
        },
    },
    watch:{
        value:{
            handler(nval) {
                if (typeof nval == 'string') {
                    this.$nextTick(() => {
                        this.list=nval.split(',')
                    })
                }else{
                    this.list=nval
                }
            },
            immediate: true,
            deep: false,
        },
    },
    data(){
        return{
            list:[]
        }
    },

}
</script>
