<script>
export default {
    name: 'numberColor',
    props: {
        value: {
            required: true,
            type: Number,
        },
    },
}
</script>

<template>
    <div>
        <!--表格文字颜色  -->
        <div v-if="value=='1'" style="color: red">{{ value }}</div>
        <div v-else-if="value=='2'" style="color: green">{{ value }}</div>
        <div v-else-if="value=='3'" style="color: blue">{{ value }}</div>
        <div v-else>{{ value }}</div>
    </div>
</template>

<style scoped lang="scss">

</style>
