var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.value == "1"
      ? _c("div", { staticStyle: { color: "red" } }, [
          _vm._v(_vm._s(_vm.value)),
        ])
      : _vm.value == "2"
      ? _c("div", { staticStyle: { color: "green" } }, [
          _vm._v(_vm._s(_vm.value)),
        ])
      : _vm.value == "3"
      ? _c("div", { staticStyle: { color: "blue" } }, [
          _vm._v(_vm._s(_vm.value)),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.value))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }