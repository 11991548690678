var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? _vm._l(_vm.value.split(","), function (item, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticClass: "ml5",
                attrs: {
                  type: index / 2 == 0 ? "" : "warning",
                  effect: "dark",
                  size: "small",
                },
              },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }