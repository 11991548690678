<template>
    <div v-html="value" @click="handlrClick()" v-bind="$attrs" class="line4" style="display: inline"> </div>
</template>

<script>
export default {
    name: 'QuickAdminText',
    props: {
        value: {
            required: true,
            type: String | Number,
        },
        row: {
            required: true,
            type: String | Object,
        },
    },
    methods:{
        handlrClick(){
            if(this.$attrs.fun){
                this.$attrs.fun('',this.row)
            }

        }
    }
}
</script>
<style>
.cBlue {
    color: #2979ff;
}
.line4{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
</style>
