var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "line4",
        domProps: { innerHTML: _vm._s(_vm.value) },
        on: {
          click: function ($event) {
            return _vm.handlrClick()
          },
        },
      },
      "div",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }