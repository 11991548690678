var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-admin" },
    [
      _c(
        "el-tag",
        {
          staticClass: "my-tag",
          attrs: {
            effect: "dark",
            type: _vm.value.type,
            size: "small",
            color: _vm.value.color ? _vm.value.color : "",
            hit: false,
          },
        },
        [_vm._v(" " + _vm._s(_vm.value.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }